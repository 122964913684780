import * as Actions from '../actions';

const initialState = {
	data: {},
	resultados: null,
	buscando: null,
	categorias: [],
	categoria: null,
	termino: '',
	urlstream: '',
	publicidad: false,
	priceSortMejores: null,
	priceSortResultados: 'desc',
	layoutDirection: 'vertical',
	obteniendo: false,
	escribiendo: false,
	error: false,
	errorMensaje: 'Ha ocurrido un error al obtener los resultados. Intentalo con otra búsqueda.',
	duracion: 0,

};

const origenesReducer = function (state = initialState, action) {


	switch (action.type) {

		case Actions.GET_ORIGENES:
			return { ...state, data: action.payload };

		case Actions.GET_ORIGENES_DATA:
			//console.log(action.payload)
			return {
				...state,
				data: action.payload
			}

		case Actions.UPDATE_ORIGENES_DATA:
			return {
				...state,
				data: {
					...state.data,
					[action.payload.id]: action.payload.value
				}
			}
		case Actions.CLEAR_ORIGENES_DATA:
			return {
				...state,
				data: {}
			}

		case Actions.SAVE_ORIGENES_DATA:
			return { ...state }


		case Actions.UPDATE_COMPARA_DATA:
			return {
				...state,
				[action.payload.id]: action.payload.value
				/*
				resultados: {
					...state.resultados,
					[action.payload.id]: action.payload.value
				}
					*/
			}

		case Actions.UPDATE_COMPARA_BUSCANDO:
			//console.log(action.payload)
			return {
				...state,
				buscando: action.payload
			}

		default:
			return state;
	}
};

export default origenesReducer;
